import React from 'react'

export const Firefox = props => (
  <svg
    version="1.1"
    viewBox="0 0 87.419 81.967"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    focusable="false"
  >
    <defs>
      <linearGradient
        id="x"
        x1="70.786"
        x2="6.447"
        y1="12.393"
        y2="74.468"
        gradientTransform="translate(3.7 -.0040855)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff44f" offset=".048" />
        <stop stopColor="#ffe847" offset=".111" />
        <stop stopColor="#ffc830" offset=".225" />
        <stop stopColor="#ff980e" offset=".368" />
        <stop stopColor="#ff8b16" offset=".401" />
        <stop stopColor="#ff672a" offset=".462" />
        <stop stopColor="#ff3647" offset=".534" />
        <stop stopColor="#e31587" offset=".705" />
      </linearGradient>
      <radialGradient
        id="w"
        cx="-7907.2"
        cy="-8515.1"
        r="80.797"
        gradientTransform="translate(7978.7 8524)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffbd4f" offset=".129" />
        <stop stopColor="#ffac31" offset=".186" />
        <stop stopColor="#ff9d17" offset=".247" />
        <stop stopColor="#ff980e" offset=".283" />
        <stop stopColor="#ff563b" offset=".403" />
        <stop stopColor="#ff3750" offset=".467" />
        <stop stopColor="#f5156c" offset=".71" />
        <stop stopColor="#eb0878" offset=".782" />
        <stop stopColor="#e50080" offset=".86" />
      </radialGradient>
      <radialGradient
        id="v"
        cx="-7936.7"
        cy="-8482.1"
        r="80.797"
        gradientTransform="translate(7978.7 8524)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#960e18" offset=".3" />
        <stop stopColor="#b11927" stopOpacity=".74" offset=".351" />
        <stop stopColor="#db293d" stopOpacity=".343" offset=".435" />
        <stop stopColor="#f5334b" stopOpacity=".094" offset=".497" />
        <stop stopColor="#ff3750" stopOpacity="0" offset=".53" />
      </radialGradient>
      <radialGradient
        id="u"
        cx="-7927"
        cy="-8533.5"
        r="58.534"
        gradientTransform="translate(7978.7 8524)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff44f" offset=".132" />
        <stop stopColor="#ffdc3e" offset=".252" />
        <stop stopColor="#ff9d12" offset=".506" />
        <stop stopColor="#ff980e" offset=".526" />
      </radialGradient>
      <radialGradient
        id="t"
        cx="-7945.6"
        cy="-8461"
        r="38.471"
        gradientTransform="translate(7978.7 8524)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3a8ee6" offset=".353" />
        <stop stopColor="#5c79f0" offset=".472" />
        <stop stopColor="#9059ff" offset=".669" />
        <stop stopColor="#c139e6" offset="1" />
      </radialGradient>
      <radialGradient
        id="s"
        cx="-7935.6"
        cy="-8491.5"
        r="20.397"
        gradientTransform="matrix(.972 -.235 .275 1.138 10095 7833.8)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9059ff" stopOpacity="0" offset=".206" />
        <stop stopColor="#8c4ff3" stopOpacity=".064" offset=".278" />
        <stop stopColor="#7716a8" stopOpacity=".45" offset=".747" />
        <stop stopColor="#6e008b" stopOpacity=".6" offset=".975" />
      </radialGradient>
      <radialGradient
        id="r"
        cx="-7937.7"
        cy="-8518.4"
        r="27.676"
        gradientTransform="translate(7978.7 8524)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ffe226" offset="0" />
        <stop stopColor="#ffdb27" offset=".121" />
        <stop stopColor="#ffc82a" offset=".295" />
        <stop stopColor="#ffa930" offset=".502" />
        <stop stopColor="#ff7e37" offset=".732" />
        <stop stopColor="#ff7139" offset=".792" />
      </radialGradient>
      <radialGradient
        id="q"
        cx="-7916"
        cy="-8536"
        r="118.08"
        gradientTransform="translate(7978.7 8524)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff44f" offset=".113" />
        <stop stopColor="#ff980e" offset=".456" />
        <stop stopColor="#ff5634" offset=".622" />
        <stop stopColor="#ff3647" offset=".716" />
        <stop stopColor="#e31587" offset=".904" />
      </radialGradient>
      <radialGradient
        id="p"
        cx="-7927.2"
        cy="-8522.9"
        r="86.499"
        gradientTransform="matrix(.105 .995 -.653 .069 -4680.3 8470.2)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff44f" offset="0" />
        <stop stopColor="#ffe847" offset=".06" />
        <stop stopColor="#ffc830" offset=".168" />
        <stop stopColor="#ff980e" offset=".304" />
        <stop stopColor="#ff8b16" offset=".356" />
        <stop stopColor="#ff672a" offset=".455" />
        <stop stopColor="#ff3647" offset=".57" />
        <stop stopColor="#e31587" offset=".737" />
      </radialGradient>
      <radialGradient
        id="o"
        cx="-7938.4"
        cy="-8508.2"
        r="73.72"
        gradientTransform="translate(7978.7 8524)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff44f" offset=".137" />
        <stop stopColor="#ff980e" offset=".48" />
        <stop stopColor="#ff5634" offset=".592" />
        <stop stopColor="#ff3647" offset=".655" />
        <stop stopColor="#e31587" offset=".904" />
      </radialGradient>
      <radialGradient
        id="n"
        cx="-7918.9"
        cy="-8503.9"
        r="80.686"
        gradientTransform="translate(7978.7 8524)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff44f" offset=".094" />
        <stop stopColor="#ffe141" offset=".231" />
        <stop stopColor="#ffaf1e" offset=".509" />
        <stop stopColor="#ff980e" offset=".626" />
      </radialGradient>
      <linearGradient
        id="m"
        x1="70.013"
        x2="15.267"
        y1="12.061"
        y2="66.806"
        gradientTransform="translate(3.7 -.0040855)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff44f" stopOpacity=".8" offset=".167" />
        <stop stopColor="#fff44f" stopOpacity=".634" offset=".266" />
        <stop stopColor="#fff44f" stopOpacity=".217" offset=".489" />
        <stop stopColor="#fff44f" stopOpacity="0" offset=".6" />
      </linearGradient>
    </defs>
    <path
      d="m79.616 26.827c-1.684-4.052-5.1-8.427-7.775-9.81a40.266 40.266 0 0 1 3.925 11.764l7e-3 0.065c-4.382-10.925-11.813-15.33-17.882-24.922-0.307-0.485-0.614-0.971-0.913-1.484-0.171-0.293-0.308-0.557-0.427-0.8a7.053 7.053 0 0 1-0.578-1.535 0.1 0.1 0 0 0-0.088-0.1 0.138 0.138 0 0 0-0.073 0c-5e-3 0-0.013 9e-3 -0.019 0.011s-0.019 0.011-0.028 0.015l0.015-0.026c-9.735 5.7-13.038 16.252-13.342 21.53a19.387 19.387 0 0 0-10.666 4.111 11.587 11.587 0 0 0-1-0.758 17.968 17.968 0 0 1-0.109-9.473 28.705 28.705 0 0 0-9.329 7.21h-0.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294 0.687 28.225 28.225 0 0 0-3.788 3.245 33.845 33.845 0 0 0-3.623 4.347v6e-3 -7e-3a32.733 32.733 0 0 0-5.2 11.743l-0.052 0.256c-0.073 0.341-0.336 2.049-0.381 2.42 0 0.029-6e-3 0.056-9e-3 0.085a36.937 36.937 0 0 0-0.629 5.343v0.2a38.759 38.759 0 0 0 76.954 6.554c0.065-0.5 0.118-0.995 0.176-1.5a39.857 39.857 0 0 0-2.514-19.469zm-44.67 30.338c0.181 0.087 0.351 0.181 0.537 0.264l0.027 0.017q-0.282-0.135-0.564-0.281zm8.878-23.376zm31.952-4.934v-0.037l7e-3 0.041z"
      fill="url(#x)"
    />
    <path
      d="m79.616 26.827c-1.684-4.052-5.1-8.427-7.775-9.81a40.266 40.266 0 0 1 3.925 11.764v0.037l7e-3 0.041a35.1 35.1 0 0 1-1.206 26.158c-4.442 9.531-15.194 19.3-32.024 18.825-18.185-0.515-34.2-14.009-37.194-31.683-0.545-2.787 0-4.2 0.274-6.465a28.876 28.876 0 0 0-0.623 5.348v0.2a38.759 38.759 0 0 0 76.954 6.554c0.065-0.5 0.118-0.995 0.176-1.5a39.857 39.857 0 0 0-2.514-19.469z"
      fill="url(#w)"
    />
    <path
      d="m79.616 26.827c-1.684-4.052-5.1-8.427-7.775-9.81a40.266 40.266 0 0 1 3.925 11.764v0.037l7e-3 0.041a35.1 35.1 0 0 1-1.206 26.158c-4.442 9.531-15.194 19.3-32.024 18.825-18.185-0.515-34.2-14.009-37.194-31.683-0.545-2.787 0-4.2 0.274-6.465a28.876 28.876 0 0 0-0.623 5.348v0.2a38.759 38.759 0 0 0 76.954 6.554c0.065-0.5 0.118-0.995 0.176-1.5a39.857 39.857 0 0 0-2.514-19.469z"
      fill="url(#v)"
    />
    <path
      d="m60.782 31.383c0.084 0.059 0.162 0.118 0.241 0.177a21.1 21.1 0 0 0-3.6-4.695c-12.046-12.048-3.157-26.123-1.658-26.838l0.015-0.022c-9.735 5.7-13.038 16.252-13.342 21.53 0.452-0.031 0.9-0.069 1.362-0.069a19.56 19.56 0 0 1 16.982 9.917z"
      fill="url(#u)"
    />
    <path
      d="m43.825 33.789c-0.064 0.964-3.47 4.289-4.661 4.289-11.021 0-12.81 6.667-12.81 6.667 0.488 5.614 4.4 10.238 9.129 12.684 0.216 0.112 0.435 0.213 0.654 0.312q0.569 0.252 1.138 0.466a17.235 17.235 0 0 0 5.043 0.973c19.317 0.906 23.059-23.1 9.119-30.066a13.38 13.38 0 0 1 9.345 2.269 19.56 19.56 0 0 0-16.982-9.917c-0.46 0-0.91 0.038-1.362 0.069a19.387 19.387 0 0 0-10.666 4.111c0.591 0.5 1.258 1.168 2.663 2.553 2.63 2.591 9.375 5.275 9.39 5.59z"
      fill="url(#t)"
    />
    <path
      d="m43.825 33.789c-0.064 0.964-3.47 4.289-4.661 4.289-11.021 0-12.81 6.667-12.81 6.667 0.488 5.614 4.4 10.238 9.129 12.684 0.216 0.112 0.435 0.213 0.654 0.312q0.569 0.252 1.138 0.466a17.235 17.235 0 0 0 5.043 0.973c19.317 0.906 23.059-23.1 9.119-30.066a13.38 13.38 0 0 1 9.345 2.269 19.56 19.56 0 0 0-16.982-9.917c-0.46 0-0.91 0.038-1.362 0.069a19.387 19.387 0 0 0-10.666 4.111c0.591 0.5 1.258 1.168 2.663 2.553 2.63 2.591 9.375 5.275 9.39 5.59z"
      fill="url(#s)"
    />
    <path
      d="m29.965 24.357c0.314 0.2 0.573 0.374 0.8 0.531a17.968 17.968 0 0 1-0.109-9.473 28.705 28.705 0 0 0-9.329 7.21c0.189-5e-3 5.811-0.106 8.638 1.732z"
      fill="url(#r)"
    />
    <path
      d="m5.354 42.159c2.991 17.674 19.009 31.168 37.194 31.683 16.83 0.476 27.582-9.294 32.024-18.825a35.1 35.1 0 0 0 1.206-26.158v-0.037c0-0.029-6e-3 -0.046 0-0.037l7e-3 0.065c1.375 8.977-3.191 17.674-10.329 23.555l-0.022 0.05c-13.908 11.327-27.218 6.834-29.912 5q-0.282-0.135-0.564-0.281c-8.109-3.876-11.459-11.264-10.741-17.6a9.953 9.953 0 0 1-9.181-5.775 14.618 14.618 0 0 1 14.249-0.572 19.3 19.3 0 0 0 14.552 0.572c-0.015-0.315-6.76-3-9.39-5.59-1.405-1.385-2.072-2.052-2.663-2.553a11.587 11.587 0 0 0-1-0.758c-0.23-0.157-0.489-0.327-0.8-0.531-2.827-1.838-8.449-1.737-8.635-1.732h-0.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294 0.687 28.225 28.225 0 0 0-3.788 3.245 33.845 33.845 0 0 0-3.638 4.337v6e-3 -7e-3a32.733 32.733 0 0 0-5.2 11.743c-0.019 0.079-1.396 6.099-0.717 9.221z"
      fill="url(#q)"
    />
    <path
      d="m57.425 26.865a21.1 21.1 0 0 1 3.6 4.7c0.213 0.161 0.412 0.321 0.581 0.476 8.787 8.1 4.183 19.55 3.84 20.365 7.138-5.881 11.7-14.578 10.329-23.555-4.384-10.93-11.815-15.335-17.884-24.927-0.307-0.485-0.614-0.971-0.913-1.484-0.171-0.293-0.308-0.557-0.427-0.8a7.053 7.053 0 0 1-0.578-1.535 0.1 0.1 0 0 0-0.088-0.1 0.138 0.138 0 0 0-0.073 0c-5e-3 0-0.013 9e-3 -0.019 0.011s-0.019 0.011-0.028 0.015c-1.499 0.711-10.388 14.786 1.66 26.834z"
      fill="url(#p)"
    />
    <path
      d="m61.6 32.036c-0.169-0.155-0.368-0.315-0.581-0.476-0.079-0.059-0.157-0.118-0.241-0.177a13.38 13.38 0 0 0-9.345-2.269c13.94 6.97 10.2 30.972-9.119 30.066a17.235 17.235 0 0 1-5.043-0.973q-0.569-0.213-1.138-0.466c-0.219-0.1-0.438-0.2-0.654-0.312l0.027 0.017c2.694 1.839 16 6.332 29.912-5l0.022-0.05c0.347-0.81 4.951-12.263-3.84-20.36z"
      fill="url(#o)"
    />
    <path
      d="m26.354 44.745s1.789-6.667 12.81-6.667c1.191 0 4.6-3.325 4.661-4.289a19.3 19.3 0 0 1-14.552-0.572 14.618 14.618 0 0 0-14.249 0.572 9.953 9.953 0 0 0 9.181 5.775c-0.718 6.337 2.632 13.725 10.741 17.6 0.181 0.087 0.351 0.181 0.537 0.264-4.733-2.445-8.641-7.069-9.129-12.683z"
      fill="url(#n)"
    />
    <path
      d="m79.616 26.827c-1.684-4.052-5.1-8.427-7.775-9.81a40.266 40.266 0 0 1 3.925 11.764l7e-3 0.065c-4.382-10.925-11.813-15.33-17.882-24.922-0.307-0.485-0.614-0.971-0.913-1.484-0.171-0.293-0.308-0.557-0.427-0.8a7.053 7.053 0 0 1-0.578-1.535 0.1 0.1 0 0 0-0.088-0.1 0.138 0.138 0 0 0-0.073 0c-5e-3 0-0.013 9e-3 -0.019 0.011s-0.019 0.011-0.028 0.015l0.015-0.026c-9.735 5.7-13.038 16.252-13.342 21.53 0.452-0.031 0.9-0.069 1.362-0.069a19.56 19.56 0 0 1 16.982 9.917 13.38 13.38 0 0 0-9.345-2.269c13.94 6.97 10.2 30.972-9.119 30.066a17.235 17.235 0 0 1-5.043-0.973q-0.569-0.213-1.138-0.466c-0.219-0.1-0.438-0.2-0.654-0.312l0.027 0.017q-0.282-0.135-0.564-0.281c0.181 0.087 0.351 0.181 0.537 0.264-4.733-2.446-8.641-7.07-9.129-12.684 0 0 1.789-6.667 12.81-6.667 1.191 0 4.6-3.325 4.661-4.289-0.015-0.315-6.76-3-9.39-5.59-1.405-1.385-2.072-2.052-2.663-2.553a11.587 11.587 0 0 0-1-0.758 17.968 17.968 0 0 1-0.109-9.473 28.705 28.705 0 0 0-9.329 7.21h-0.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294 0.687 28.225 28.225 0 0 0-3.788 3.245 33.845 33.845 0 0 0-3.623 4.347v6e-3 -7e-3a32.733 32.733 0 0 0-5.2 11.743l-0.052 0.256c-0.073 0.341-0.4 2.073-0.447 2.445v0a45.094 45.094 0 0 0-0.572 5.403v0.2a38.759 38.759 0 0 0 76.954 6.554c0.065-0.5 0.118-0.995 0.176-1.5a39.857 39.857 0 0 0-2.514-19.469zm-3.845 1.991 7e-3 0.041z"
      fill="url(#m)"
    />
  </svg>
)

import React from 'react'
import styled from '@emotion/styled'
import '../css/typography.css'
import SEO from '../components/seo'
import { Chrome } from '../components/icon/chrome'
import { Firefox } from '../components/icon/firefox'
import { Edge } from '../components/icon/edge'
import BrowserUpdateBackground from '../images/browser-update.png'

const Container = styled.div`
  height: 100vh;
  color: #222;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    background-image: url(${BrowserUpdateBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    z-index: -100;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &::after {
    content: '';
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -90;
  }
`

const Overlay = styled.div`
  width: 100%;
  height: calc(100% - 30%);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1441px) {
    max-width: 1440px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    width: 80%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: calc(100% - 30%);
    width: calc(100% - 80px);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    width: calc(100% - 60px);
    align-items: flex-start;
  }
`

const Heading = styled.h1`
  margin-bottom: 60px;
  font-family: 'Prospectus Std', Times, 'Times New Roman', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 42px;
  letter-spacing: 0.02em;
  text-align: center;

  @media screen and (max-width: 767px) {
    text-align: start;
    margin-top: 0.67em;
  }
`

const BrowserLinkList = styled.ul`
  list-style: none;
  padding: 0;
  width: 80%;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
`

const BrowserLinkListItem = styled.li`
  width: 100%;
  &:not(:last-child) {
    margin-right: 0;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
  }

  @media (any-hover: none) {
    &.not-mobile {
      display: none;
    }
  }
`

const LinkContent = styled.div`
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const Link = styled.a`
  font-family: 'Source Sans Pro Light', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.06em;

  svg {
    width: 80px;
    height: 80px;
  }

  .link-text {
    margin-top: 20px;
    text-decoration: underline;
    text-align: center;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .svg {
      width: 60px;
      height: 60px;
    }
  }

  @media screen and (max-width: 767px) {
    .svg {
      width: 60px;
      height: 60px;
    }

    .link-text {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  @media (any-hover: none) {
    opacity: 1;
  }
`

const Description = styled.p`
  font-family: 'Source Sans Pro Light', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.06em;
  width: 60%;

  @media screen and (max-width: 767px) {
    text-align: start;
    width: 100%;
  }
`

// Regex for testing IE browsers:
// /^.*(MSIE [1-9](?:\.[0-9]+)?)|(Trident\/[4-7]\.0).*$/g
const NotFoundPage = () => (
  <>
    <SEO title="Crown Sydney | Browser Update" />
    <Container>
      <Overlay>
        <Content>
          <Heading>Please update your browser</Heading>
          <BrowserLinkList>
            <BrowserLinkListItem>
              <Link
                className="browser-link-list-item link"
                href="https://www.google.com/chrome/browser"
                target="_blank"
                rel="noreferrer"
              >
                <LinkContent className="browser-link-list-item link-content">
                  <Chrome />
                  <span className="link-text">Download Chrome</span>
                </LinkContent>
              </Link>
            </BrowserLinkListItem>
            <BrowserLinkListItem>
              <Link
                className="browser-link-list-item link"
                href="https://www.mozilla.org/firefox"
                target="_blank"
                rel="noreferrer"
              >
                <LinkContent className="browser-link-list-item link-content">
                  <Firefox />
                  <span className="link-text">Download Firefox</span>
                </LinkContent>
              </Link>
            </BrowserLinkListItem>
            <BrowserLinkListItem>
              <Link
                className="browser-link-list-item link"
                href="https://www.microsoft.com/en-us/edge"
                target="_blank"
                rel="noreferrer"
              >
                <LinkContent className="browser-link-list-item link-content">
                  <Edge />
                  <span className="link-text">Download Edge</span>
                </LinkContent>
              </Link>
            </BrowserLinkListItem>
          </BrowserLinkList>
          <Description>
            For an optimal experience browsing the Crown Weddings site, we
            recommend that you update your browser. Your current browser is
            either out of date or no longer supported.
          </Description>
        </Content>
      </Overlay>
    </Container>
  </>
)

export default NotFoundPage
